import FormattedMessage from "components/common/FormattedMessage";
import { ReactNode, StyleHTMLAttributes } from "react";
import { Button, Tooltip } from "antd";
import Reactions from "modules/smart/components/list-item/base-list-item/reactions/reactions";
import PostStatusDisplay, { AdStatusDisplay, CombinedPostAndAdStatusDisplay, PlatformStatusDisplay, StatusDisplay } from "modules/posts/displays/StatusDisplay";
import { ProviderIconList } from "components/icons/ProviderIcons";
import DateTimeDisplay, { DateTimeDisplayWithTooltip } from "components/displays/DateTimeDisplay";
import { CenterdCell } from "modules/posts/CommonCells";
import DateDisplayCell from "modules/posts/DateDisplayCell";
import ResultDisplay from "modules/posts/postTools/ResultDisplay";
import { AdStateAndPromoteButton, PostStateAndPromoteButton } from "modules/posts/postTools/StateAndPromoteButtons";
import CellWithMultipleValues from "modules/posts/displays/CellWithMultipleValues";
import { InfinityListHeader } from "./infinityListHeading";
import { AdSourceAndCategoryDisplay, ProviderSourceDisplay } from "modules/posts/displays/SourceAndTypeDisplay";
import Post from "models/post";
import ReactionsDisplay from "modules/posts/displays/Reactions/ReactionsDisplay";
import CellValueWithSubscript from "modules/posts/displays/CellValueWithSubscript";
import ValueDisplayWithCounter from "components/common/ValueDisplayWithCounter";
import ConversionsDisplay from "modules/posts/displays/Conversions/ConversionsDisplay";
import { ManagePostBots } from "modules/bot/components/bot-list-item/options/ManagePostBots";
import { constructAsset } from "models/assetClassHelpers";
import PlatformIcon from "components/common/icons/PlatformIcon";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faHeart } from "@fortawesome/pro-light-svg-icons";
import BotContextProvider from "modules/bot/components/BotContext";


export const CalculateGridStyle = (columns: any, sizeIndex: any) => {
    let t = "";
    let areas = "";

    columns.forEach((c: any) => {
        if (!c.size || !c.size[sizeIndex] || c.size[sizeIndex] === false || c.size[sizeIndex] === "0") return;
        areas = areas + " " + c.key;
        t = t + " " + c.size[sizeIndex];
    })

    return {
        gridTemplateColumns: t,
        gridTemplateAreas: '"' + areas + '"',
    }
}

export type InfinityColumnProps = {
    key: string;
    size?: (string | boolean)[];
    title?: ReactNode;
    headerTooltip?: ReactNode;
    style?: React.CSSProperties;
    wrapperStyle?: React.CSSProperties;
    headingStyle?: React.CSSProperties;
    breakpoint?: string | string[];
    render: (item: any, query: any) => any;
    center?: boolean;
}

const freeResult = <FormattedMessage
    id="posts.list.info.free"
    defaultMessage="Free"
/>

const columnHeadings: { [key: string]: ReactNode } = {
    "description": <FormattedMessage
        id="posts.list.header.description"
        defaultMessage="Description"
    />,
    "provider": <FormattedMessage
        id="posts.list.header.platform"
        defaultMessage="Platform"
    />,
    "status": <FormattedMessage
        id="posts.list.header.status"
        defaultMessage="Status"
    />,
    "reach": <FormattedMessage
        id="posts.list.header.calculatedReach"
        defaultMessage="People Reached"
    />,
    "impressions": <FormattedMessage
        id="posts.list.header.impressions"
        defaultMessage="Impressions"
    />,
    "interactions": <FormattedMessage
        id="posts.list.header.interactions"
        defaultMessage="Interactions"
    />,
    "clicks": <FormattedMessage
        id="posts.list.header.clicks"
        defaultMessage="Clicks"
    />,
    "result": <FormattedMessage
        id="posts.list.header.result"
        defaultMessage="Result"
    />,
    "reactions": <FormattedMessage
        id="posts.list.header.reactions"
        defaultMessage="Reactions"
    />,
    "conversions": <FormattedMessage
        id="posts.list.header.conversions"
        defaultMessage="Conversions"
    />,
    "cpr": <FormattedMessage
        id="posts.list.header.costPerResult"
        defaultMessage="CpR"
    />,
    "date_combined": <FormattedMessage
        id="posts.list.header.dateCombined"
        defaultMessage="Dates"
    />,
    "promotion": <FormattedMessage
        id="posts.list.header.promotion"
        defaultMessage="Promotion"
    />,
    "published_at": <FormattedMessage
        id="posts.list.header.published"
        defaultMessage="Published"
    />,
    "created_at": <FormattedMessage
        id="posts.list.header.created"
        defaultMessage="Created"
    />,
    "budget_spend": <FormattedMessage
        id="posts.list.header.spend"
        defaultMessage="Spend"
    />,
    "budget": <FormattedMessage
        id="posts.list.header.budget"
        defaultMessage="Budget"
    />,
    "expires": <FormattedMessage
        id="posts.list.header.endTime"
        defaultMessage="Expires"
    />,
    "likes": <FormattedMessage
        id="posts.list.header.likes"
        defaultMessage="Likes"
    />,
    "saved": <FormattedMessage
        id="posts.list.header.saved"
        defaultMessage="Saved"
    />,
    "shares": <FormattedMessage
        id="posts.list.header.shares"
        defaultMessage="Shares"
    />,
    "engagement": <FormattedMessage
        id="posts.list.header.engagement"
        defaultMessage="Engagement"
    />,
}

const columnHeadingsTooltip: { [key: string]: ReactNode } = {

}

const columnHeadingsSubtitle: { [key: string]: ReactNode } = {
    "budget": <FormattedMessage
        id="posts.list.header.budget.subtitle"
        defaultMessage="Spend / Daily"
    />,
    "status": <FormattedMessage
        id="posts.list.header.status.subtitle"
        defaultMessage="Post/Adverts"
    />,
    "adDuration": <FormattedMessage
        id="posts.list.header.adDuration.subtitle"
        defaultMessage="Ad Duration"
    />,
}


const size50 = "minmax(50px, 75px)";
const size60 = "minmax(60px, 75px)";
const size70 = "minmax(70px, 100px)";
const size75 = "minmax(75px, 120px)";
const size90 = "minmax(90px, 150px)";
const size150 = "minmax(150px, 200px)";

const sizeNone = [false, false, false, false, false, false]


// ["125px", "125px", "125px", "125px", "125px", "125px"],

export const columnSize = {
    image: ["70px", "70px", "70px", "70px", "70px", "70px"],
    description: [false, false, false, false, "1fr", "1fr"],
    provider: [false, "75px", "75px", "75px", "75px", "75px"],
    adStatus: ["150px", "150px", "150px", "150px", "150px", "150px"],
    //adStatus: ["50px", "50px", "50px", "50px", "50px", "50px"],
    reach: [false, size50, size50, size50, size50, size50],
    interactions: [false, false, size75, size75, size75, size75],
    clicks: [false, false, false, size50, size50, size50],
    result: [false, false, false, false, size70, size70],
    reactions: [false, false, false, false, "75px", "75px"],
    conversions: [false, false, false, false, "75px", "75px"],
    cpr: sizeNone,
    //cpr: [false, false, false, false, "50px", "50px"],
    published_at: sizeNone,
    //published_at: [false, false, false, size75, size75, size75],
    // promotion: [false, false, false, false, false, false],
    promotion: [false, "100px", "100px", "100px", "100px", "100px"],
    budgetCombined: [false, size70, size70, size70, size70, size70],
    budgetSpent: sizeNone,
    //budgetSpent:    [false, size75, size75, size75, size75, size75],
    budgetSet: sizeNone,
    //budgetSet:          [false, size75, size75, size75, size75, size75],
    //expires:        [false, false, false, size70, size70, size70],
    expires: [false, false, false, false, false, false],
    tools: ["200px", "200px", "200px", "200px", "200px", "200px"],
    // tools: ["100px", "100px", "100px", "100px", "100px", "100px"],

    size50: [size50, size50, size50, size50, size50, size50],
    size60: [size60, size60, size60, size60, size60, size60],
    size70: [size70, size70, size70, size70, size70, size70],
    size75: [size75, size75, size75, size75, size75, size75],
    size90: [size90, size90, size90, size90, size90, size90],
    size150: [size150, size150, size150, size150, size150, size150],

}

export const PostListHeaders: InfinityColumnProps[] = [
    {
        key: "image",
        size: columnSize.image,
        render: () => null
    },
    {
        key: "description",
        size: columnSize.description,
        render: () => columnHeadings["description"]
    },
    {
        key: "provider",
        size: columnSize.provider,
        render: () => columnHeadings["provider"]
    },
    {
        key: "adStatus",
        size: columnSize.adStatus,
        wrapperStyle: { overflow: "visible" },
        render: () => <InfinityListHeader
            title={columnHeadings["status"]}
            subtitle={columnHeadingsSubtitle["status"]}
        />
    },
    {
        key: "reach",
        size: columnSize.reach,
        wrapperStyle: { justifyItems: "end", overflow: "visible", width: "110px" },
        render: () => <InfinityListHeader
            title={columnHeadings["reach"]}
            subtitle={columnHeadings["impressions"]}
        />
    },
    {
        key: "interactions",
        size: columnSize.interactions,
        wrapperStyle: { justifyItems: "end" },
        render: () => columnHeadings["interactions"],
    },
    {
        key: "clicks",
        size: columnSize.clicks,
        wrapperStyle: { justifyItems: "end" },
        render: () => columnHeadings["clicks"],
    },
    {
        key: "result",
        size: columnSize.result,
        render: () => columnHeadings["result"],
    },
    {
        key: "reactions",
        size: columnSize.reactions,
        render: () => columnHeadings["reactions"],
    },
    {
        key: "conversions",
        size: columnSize.conversions,
        render: () => columnHeadings["conversions"],
    },
    {
        key: "cpr",
        headerTooltip: <FormattedMessage
            id="posts.list.header.costPerClick.headerTooltip"
            defaultMessage="Cost per click"
        />,
        size: columnSize.cpr,
        render: () => columnHeadings["cpr"],
    },
    {
        key: "pulished_at",
        size: columnSize.published_at,
        render: () => <InfinityListHeader
            title={columnHeadings["published_at"]}
            subtitle={columnHeadingsSubtitle["adDuration"]}
        />
    },
    {
        key: "budgetCombined",
        size: columnSize.budgetCombined,
        render: () => <InfinityListHeader
            title={columnHeadings["budget"]}
        />
    },
    {
        key: "budgetSpent",
        size: columnSize.budgetSpent,
        render: () => columnHeadings["budget_spend"],
    },
    {
        key: "budgetSet",
        size: columnSize.budgetSet,
        render: () => columnHeadings["budget"],
    },
    {
        key: "expires",
        size: columnSize.expires,
        render: () => <InfinityListHeader
            title={columnHeadings["expires"]}
        />
    },
    {
        key: "promotion",
        size: columnSize.promotion,
        render: () => <InfinityListHeader
            title={columnHeadings["published_at"]}
            subtitle={columnHeadingsSubtitle["adDuration"]}
        />
    },
    {
        key: "tools",
        title: "",
        size: columnSize.tools,
        wrapperStyle: { overflow: "visible" },
        render: () => null
    },
]

const switchByPlatform = (config: Record<string, InfinityColumnProps["render"]>) => {

    return (item: any, query: any) => {
        const platform = query.platform || query.provider;
        if (!config[platform]) {
            if (config["default"]) {
               if (typeof config["default"] === "function") return config["default"](item, query);
                return config["default"];
            }
            return null;
        }
        if (typeof config[platform] === "function") return config[platform](item, query);
        return config[platform];
    }

}

/////////////////////////////////////////////////////////////////////
//  _____     ____     _____   _______ 
// |  __ \   / __ \   / ____| |__   __|
// | |__) | | |  | | | (___      | |   
// |  ___/  | |  | |  \___ \     | |   
// | |      | |__| |  ____) |    | |   
// |_|       \____/  |_____/     |_| 
/////////////////////////////////////////////////////////////////////

export const DefaultColumnsPosts: InfinityColumnProps[] = [
    {
        key: "image",
        size: columnSize.image,
        render: (item: Post) => {
            if (item.image) return (<img src={item.image} style={{ objectFit: "cover", height: "70px", width: "70px", borderRadius: "10px" }} />)
            return <div></div>
        }
    },
    {
        key: "description",
        size: columnSize.description,
        render: (item: Post) => <div className="infinity-long-text">{item.getShortenedDescription()}</div>,
    },
    {
        key: "provider",
        size: columnSize.provider,
        render: (item: Post) => <ProviderIconList providers={item.getProviders()} />
    },
    {
        key: "adStatus",
        size: columnSize.adStatus,
        wrapperStyle: { overflow: "visible" },
        render: (item: Post, query: any) => <CombinedPostAndAdStatusDisplay provider="all" query={query} />
    },
    {
        key: "reach",
        size: columnSize.reach,
        style: { justifyItems: "end" },
        render: switchByPlatform({
            default: (item: Post, query: any) => <ValueDisplayWithCounter value={item.getProviderInsights("reach", "facebook")} />,
            instagram: (item: Post, query: any) => <ValueDisplayWithCounter value={item.getCombinedInsights("organic_reach")} />
        })
    },
    {
        key: "interactions",
        size: columnSize.interactions,
        style: { justifyItems: "end" },
        render: switchByPlatform({
            default: (item: Post, query: any) => <ValueDisplayWithCounter value={item.getCombinedInsights("interactions")} />,
            instagram: (item: Post, query: any) => <ValueDisplayWithCounter value={item.getCombinedInsights("total_interactions")} />
        })
    },
    {
        key: "clicks",
        size: columnSize.clicks,
        style: { justifyItems: "end" },
        render: switchByPlatform({
            default: (item: Post, query: any) => <ValueDisplayWithCounter value={item.getCombinedInsights("clicks")} />,
            instagram: (item: Post, query: any) => <ValueDisplayWithCounter value={item.getCombinedInsights("shares")} />
        })
    },
    {
        key: "result",
        size: columnSize.result,
        render: switchByPlatform({
            default: (item: Post) => "-",
            instagram: (item: Post) => <ValueDisplayWithCounter value={item.getCombinedInsights("saved")} />
        })
    },
    {
        key: "reactions",
        size: columnSize.reactions,
        wrapperStyle: { overflow: "visible" },
        render: switchByPlatform({
            facebook: (item: Post) => <div className="reactions">
                <ReactionsDisplay reactions={item.getCombinedReactions()} />
            </div>,
            google: (item: Post) => <div className="reactions">
                <ReactionsDisplay reactions={item.getCombinedReactions()} />
            </div>,
            instagram: (item: Post) => <ValueDisplayWithCounter value={item.getCombinedInsights("likes")} />
        })
    },
    {
        key: "conversions",
        size: columnSize.conversions,
        wrapperStyle: { overflow: "visible" },
        render: (item: Post) => <ConversionsDisplay conversions={item.getAllAdConversions()} />
    },
    {
        key: "cpr",
        size: columnSize.cpr,
        render: () => null
    },
    {
        key: "pulished_at",
        render: (item: Post) => <DateDisplayCell date={item["created_at"]} />,
        size: columnSize.published_at,
    },
    {
        key: "budgetCombined",
        size: columnSize.budgetCombined,
        render: (item: Post, query: any) => (
            <CellWithMultipleValues
                rows={[
                    [<FormattedMessage
                        id="posts.list.info.budget.daily"
                        defaultMessage="Daily"
                    />, item.getBudget().toFixed(2) || "-"],
                    [<FormattedMessage
                        id="posts.list.info.budget.spent"
                        defaultMessage="Spent"
                    />, item.getSpentBudget().toFixed(2) || "-"]
                ]}
            />
        )
    },
    {
        key: "budgetSpent",
        size: columnSize.budgetSpent,
        render: (item: Post, query: any) => <div>{item.getSpentBudget().toFixed(2) || "-"}</div>
    },
    {
        key: "budgetSet",
        size: columnSize.budgetSet,
        render: (item: Post, query: any) => <div>{item.getBudget().toFixed(2) || "-"}</div>
    },
    {
        key: "expires",
        size: columnSize.expires,
        render: (item: Post) => <DateTimeDisplay dateTime={item["end_time"]} showDate />,
    },
    {
        key: "promotion",
        render: (item: Post) => <div>
            <div><FormattedMessage
                id="posts.list.info.published"
                defaultMessage="Published"
            /></div>
            <div><DateTimeDisplay dateTime={item.getPublicationDate()} showDate /></div>
        </div>,
        size: columnSize.promotion,
    },
    {
        key: "tools",
        title: "",
        size: columnSize.tools,
        wrapperStyle: { overflow: "visible" },
        render: (item: Post, query: any) => <PostStateAndPromoteButton post={item} query={query} />

    },
]


///////////////////////////////////./////////////////////////////////////
// _____    _                   _______   ______    ____    _____    __  __ 
// |  __ \  | |          /\     |__   __| |  ____|  / __ \  |  __ \  |  \/  |
// | |__) | | |         /  \       | |    | |__    | |  | | | |__) | | \  / |
// |  ___/  | |        / /\ \      | |    |  __|   | |  | | |  _  /  | |\/| |
// | |      | |____   / ____ \     | |    | |      | |__| | | | \ \  | |  | |
// |_|      |______| /_/    \_\    |_|    |_|       \____/  |_|  \_\ |_|  |_|
///////////////////////////////////./////////////////////////////////////                      



export const PlatformDetailsColumns: InfinityColumnProps[] = [
    {
        key: "image",
        size: columnSize.image,
        render: (item: any, query: any) => (query.sizeIndex === 0 && <PlatformIcon platform={query.provider} />)
    },
    {
        key: "description",
        size: columnSize.description,
        render: (item: any, query: any) => <ProviderSourceDisplay provider={query.provider} />
    },
    {
        key: "provider",
        size: columnSize.provider,
        render: (item: any, query: any) => <PlatformIcon platform={query.provider} size="small" />
    },
    {
        key: "adStatus",
        size: columnSize.adStatus,
        render: (item: any, query: any) => <PlatformStatusDisplay status={item.getPostStatus(query.provider)} />
    },
    {
        key: "reach",
        size: columnSize.reach,
        style: { justifyItems: "end" },
        render: (item: any, query: any) =>
            <CellValueWithSubscript
                value={<ValueDisplayWithCounter value={item.getProviderInsights("organic_reach", query.provider)} />}
                subscript={null}
            />
    },
    {
        key: "interactions",
        size: columnSize.interactions,
        style: { justifyItems: "end" },
        render: switchByPlatform({
            facebook: (item: any, query: any) => <ValueDisplayWithCounter value={item.getProviderInsights("interactions", query.provider)} />,
            instagram: (item: any, query: any) => (
                <CellValueWithSubscript
                    value={<ValueDisplayWithCounter value={item.getProviderInsights("total_interactions", query.provider)} />}
                    subscript={"Engagement"}
                />
            ),
            google: (item: any, query: any) => <ValueDisplayWithCounter value={item.getProviderInsights("interactions", query.provider)} />,
        })
    },
    {
        key: "clicks",
        size: columnSize.clicks,
        style: { justifyItems: "end" },
        //render: (item: any, query: any) => <ValueDisplayWithCounter value={item.getProviderInsights("clicks", query.provider)} />
        render: switchByPlatform({
            facebook: (item: any, query: any) => <ValueDisplayWithCounter value={item.getProviderInsights("clicks", query.provider)} />,
            instagram: (item: any, query: any) => (
                <CellValueWithSubscript
                    value={<ValueDisplayWithCounter value={item.getProviderInsights("shares", query.provider)} />}
                    subscript={columnHeadings["shares"]}
                />
            ),
            google: (item: any, query: any) => <ValueDisplayWithCounter value={item.getProviderInsights("clicks", query.provider)} />,
        }),
    },
    {
        key: "result",
        size: columnSize.result,
        //render: (item: any, query: any) => <ValueDisplayWithCounter value={item.getProviderInsights("actions", query.provider)} />
        render: switchByPlatform({
            facebook: (item: any, query: any) => <ValueDisplayWithCounter value={item.getProviderInsights("actions", query.provider)} />,
            instagram: (item: any, query: any) => (
                <CellValueWithSubscript
                    value={<ValueDisplayWithCounter value={item.getProviderInsights("saved", query.provider)} />}
                    subscript={columnHeadings["saved"]}
                />
            ),
            google: (item: any, query: any) => <ValueDisplayWithCounter value={item.getProviderInsights("actions", query.provider)} />,
        })
    },
    {
        key: "reactions",
        size: columnSize.reactions,
        wrapperStyle: { overflow: "visible" },
        //render: (item: any, query: any) => <ReactionsDisplay reactions={item.getProviderReactions(query.provider)} />
        render: switchByPlatform({
            facebook: (item: any, query: any) => <ReactionsDisplay reactions={item.getProviderReactions(query.provider)} />,
            google: (item: any, query: any) => <ReactionsDisplay reactions={item.getProviderReactions(query.provider)} />,
            instagram: (item: any, query: any) => (
                <div style={{
                    display: "flex", 
                    flexDirection: "row",
                    alignItems: "center"
                    }}><AwesomeIcon icon={faHeart} />
                <CellValueWithSubscript
                    value={<ValueDisplayWithCounter value={item.getProviderInsights("likes", query.provider)} />}
                    subscript={columnHeadings["likes"]}
                />
                </div>
            )
        })
    },
    {
        key: "conversions",
        size: columnSize.conversions,
        wrapperStyle: { overflow: "visible" },
        render: () => null,
        //render: (item: any, query: any) => <ConversionsDisplay conversions={item.getProviderReactions(query.provider)} />
    },
    {
        key: "cpr",
        size: columnSize.cpr,
        render: (item: any, query: any) => null
        //render: (item: any, query: any) => item.getCpcByProvider(query.provider).toFixed(2)
    },
    {
        key: "pulished_at",
        size: columnSize.published_at,
        render: () => null,
    },
    {
        key: "budgetCombined",
        size: columnSize.budgetCombined,
        render: () => freeResult
    },
    {
        key: "budgetSpent",
        size: columnSize.budgetSpent,
        render: (item: any, query: any) => <div>{item.getSpentBudgetByProvider(query.provider).toFixed(2) || "-"}</div>
    },
    {
        key: "budgetSet",
        size: columnSize.budgetSet,
        render: (item: any, query: any) => <div>{item.getBudget(query.provider).toFixed(2) || "-"}</div>
    },
    {
        key: "expires",
        size: columnSize.expires,
        render: () => null,
    },
    {
        key: "promotion",
        size: columnSize.promotion,
        render: () => null,
    },
    {
        key: "tools",
        title: "",
        size: columnSize.tools,
        render: () => null,
    },
]

//////////////////////////////////////////////////////////////////////////////////////////
//             _____     _____ 
//     /\     |  __ \   / ____|
//    /  \    | |  | | | (___  
//   / /\ \   | |  | |  \___ \ 
//  / ____ \  | |__| |  ____) |
// /_/    \_\ |_____/  |_____/ 
//////////////////////////////////////////////////////////////////////////////////////////        


export const AdDetailsColumns: InfinityColumnProps[] = [
    {
        key: "image",
        size: columnSize.image,
        render: () => null,

    },
    {
        key: "description",
        size: columnSize.description,
        render: (item: any, query: any) => <AdSourceAndCategoryDisplay ad={item} />,
    },
    {
        key: "provider",
        size: ["75px", "75px", "75px", "75px", "75px", "75px"],
        render: () => null,
    },
    {
        key: "adStatus",
        size: columnSize.adStatus,
        render: (item: any, query: any) => <AdStatusDisplay ad={item} provider={query.provider} />
    },
    {
        key: "reach",
        size: columnSize.reach,
        style: { justifyItems: "end" },
        // render: (item: any, query: any) => item?.insights?.reach
        render: switchByPlatform({
            facebook: (item: any, query: any) =>
                <CellValueWithSubscript
                    value={<ValueDisplayWithCounter value={item?.insights?.reach} />}
                    subscript={<ValueDisplayWithCounter value={item?.insights?.impressions} />}
                />,
            instagram: (item: any, query: any) =>
                (item: any, query: any) =>
                    <CellValueWithSubscript
                        value={<ValueDisplayWithCounter value={item?.insights?.organic_reach} />}
                        subscript={<ValueDisplayWithCounter value={item?.insights?.impressions} />}
                    />,
            google: (item: any, query: any) => null
        })
    },
    {
        key: "interactions",
        size: columnSize.interactions,
        style: { justifyItems: "end" },
        render: (item: any, query: any) => <ValueDisplayWithCounter value={item?.insights?.post_engagement} />
    },
    {
        key: "clicks",
        size: columnSize.clicks,
        style: { justifyItems: "end" },
        render: (item: any, query: any) => <ValueDisplayWithCounter value={item?.insights?.clicks} />
    },
    {
        key: "result",
        size: columnSize.result,
        render: (item: any, query: any) => <ResultDisplay ad={item} />
    },
    {
        key: "reactions",
        size: columnSize.reactions,
        wrapperStyle: { overflow: "visible" },
        render: (item: any, query: any) => "-"
    },
    {
        key: "conversions",
        size: columnSize.conversions,
        wrapperStyle: { overflow: "visible" },
        render: (item: any, query: any) => <ConversionsDisplay conversions={item?.insights?.conversions} />
    },
    {
        key: "cpr",
        size: columnSize.cpr,
        render: (item: any, query: any) => {
            const cpr = Post.getCprByAd(item);
            return cpr ? cpr.toFixed(2) : "-"
        }
    },
    {
        key: "budgetCombined",
        size: columnSize.budgetCombined,
        render: (item: any, query: any) => (
            <CellWithMultipleValues
                onClick={(e: any) => query.handleOpen(e, { page: "budget", ad: item })}
                rows={[
                    [
                        "Daily:", query.post.getBudgetByAd(item).toFixed(2) || "-"
                    ],
                    [
                        "Spent:", query.post.getSpentBudgetByAd(item).toFixed(2) || "-"
                    ]
                ]}
            />
        )
    },
    {
        key: "budgetSpent",
        size: columnSize.budgetSpent,
        render: (item: any, query: any) => {
            return <CenterdCell onClick={(e: any) => query.handleOpen(e, { page: "budget", ad: item })}>{query.post.getSpentBudgetByAd(item).toFixed(2) || "-"}</CenterdCell>
        },
    },
    {
        key: "budgetSet",
        size: columnSize.budgetSet,
        render: (item: any, query: any) => <CenterdCell onClick={(e: any) => query.handleOpen(e, { page: "budget", ad: item })}>{query.post.getBudgetByAd(item).toFixed(2) || "-"}</CenterdCell>
    },
    {
        key: "expires",
        size: columnSize.expires,
        render: (item: any, query: any) => <DateDisplayCell onClick={(e: any) => query.handleOpen(e, { page: "duration", ad: item })} date={item?.adset?.end_time} />,
    },
    {
        key: "promotion",
        size: columnSize.promotion,
        render: (item: any, query: any) => (
            <Tooltip
                title={"hello"}
            >
                <CellWithMultipleValues
                    onClick={(e: any) => query.handleOpen(e, { page: "budget", ad: item })}
                    rows={[
                        [
                            "From:", <DateTimeDisplayWithTooltip dateTime={Post.safelyGetAdStartDate(item)} noWrapper showDate dateformat="DD MMM" />
                        ],
                        [
                            "To:", <DateTimeDisplayWithTooltip dateTime={Post.safelyGetAdEndDate(item)} noWrapper showDate dateformat="DD MMM" />
                        ]
                    ]}
                />
            </Tooltip>
        )
    },
    {
        key: "tools",
        title: "Tools",
        size: columnSize.tools,
        render: (item: any, query: any) => <AdStateAndPromoteButton query={query} ad={item} />
        //render: (item: any, query: any) => <EditFilled onClick={(e) => query.handleOpen(e, { page: "budget", ad: item })} />
    },
]


export const BotsColumns: InfinityColumnProps[] = [
    ...DefaultColumnsPosts.map(column =>
        column.key === "tools"
            ? {
                ...column, render: (item: any) => (

                    <div style={{ display: "flex" }}>
                        {item.bots && item.bots.length ? (
                            <ManagePostBots
                                postId={item.providers.facebook?.external_id}
                                bots={item.bots}
                            />
                        ) : (
                            <BotContextProvider
                                actionType="create"
                                postId={item.providers.facebook?.external_id}
                            />
                        )}
                    </div>
                )
            }
            : { ...column }
    ),
];

export const AssetColumns: InfinityColumnProps[] = [
    {
        key: "image",
        size: ["70px", "70px", "70px", "70px", "70px", "70px"],
        render: (item: any) => {
            if (item.image) return (<img src={item.image} style={{ objectFit: "cover", height: "70px", width: "70px", borderRadius: "10px" }} />)
            return <div></div>
        }
    },
    {
        key: "description",
        size: [false, false, false, false, "1fr", "1fr"],
        render: (item: any) => <div className="infinity-long-text">{item.getDescription()}</div>,
    },
    {
        key: "pulished_at",
        render: (item: any) => item["created_at"] && <DateDisplayCell date={item["created_at"]} />,
        size: [false, false, false, false, "100px", "100px"],
    },
]

export const TestAssetColumns: InfinityColumnProps[] = [
    {
        key: "image",
        size: ["70px", "70px", "70px", "70px", "70px", "70px"],
        render: (item: any) => {
            if (item.image) return (<img src={item.image} style={{ objectFit: "cover", height: "70px", width: "70px", borderRadius: "10px" }} />)
            return <div></div>
        }
    },
    {
        key: "description",
        size: ["1fr", "1fr", "1fr", "1fr", "1fr", "1fr"],
        render: (item: any) => <div className="infinity-long-text">Hello:{item.getDescription()}</div>,
    },
]

export const AOAMainColumns: InfinityColumnProps[] = [
    ...DefaultColumnsPosts.map((column) => column.key !== "tools" ? column : {
        ...column,
        render: (item: any, query: any) => <PostStateAndPromoteButton post={item} query={query} /> //Why was aoa an ad?
        //render: (item: any, query: any) => <AdStateAndPromoteButton query={query} ad={item} />  //This was here
    }),
]

export const AOAPlatformColumns: InfinityColumnProps[] = [
    ...PlatformDetailsColumns.map((column) => column.key !== "tools" ? column : {
        ...column,
        render: () => null
        //render: (item: any, query: any) => <AdStateAndPromoteButton query={query} ad={item} />
    }),
]

export const AOAAdColumns: InfinityColumnProps[] = [
    ...AdDetailsColumns.map((column) => column.key !== "tools" ? column : {
        ...column,
        render: () => null
        //render: (item: any, query: any) => <AdStateAndPromoteButton query={query} ad={item} />
    }),
]

export const LeadMainColumns: InfinityColumnProps[] = [
    ...DefaultColumnsPosts.map((column) => column.key !== "tools" ? column : {
        ...column,
        render: () => null
    }),
]

export const LeadPlatformColumns: InfinityColumnProps[] = [
    ...PlatformDetailsColumns.map((column) => column.key !== "tools" ? column : {
        ...column,
        render: () => null
    }),
]

export const LeadAdColumns: InfinityColumnProps[] = [
    ...AdDetailsColumns.map((column) => column.key !== "tools" ? column : {
        ...column,
        render: () => null
    }),
]


const postConfig = {
    columns: {
        main: DefaultColumnsPosts,
        platform: PlatformDetailsColumns,
        ads: AdDetailsColumns,
    },
    calendarComponent: null,
    getObject: (item: any) => constructAsset(item, "post")
}

export const configByType: any = {
    "post": postConfig,
    "post_facebook": postConfig,
    "post_instagram": postConfig,
    "post_google": postConfig,
    "aoa": {
        columns: {
            main: AOAMainColumns,
            platform: AOAPlatformColumns,
            ads: AOAAdColumns,
        },
        calendarComponent: null,
        getObject: (item: any) => constructAsset(item, "aoa")
    },
    "leadads": {
        columns: {
            main: LeadMainColumns,
            platform: LeadPlatformColumns,
            ads: LeadAdColumns,
        },
        calendarComponent: null,
        getObject: (item: any) => constructAsset(item, "leadads")
    },
    "asset": {
        columns: {
            main: AssetColumns,
        },
        calendarComponent: null,
        getObject: (item: any) => constructAsset(item, "asset")
    },
    "creation": {
        columns: {
            main: AssetColumns,
        },
        calendarComponent: null,
        getObject: (item: any) => constructAsset(item, "creation")
    },
}

export const botsConfig: any = {
    columns: {
        main: BotsColumns,
    },
    getObject: (postObj: any) => new Post(postObj)
}